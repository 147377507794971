@media screen and  (min-width:991px){
    .ttm-row.services-sectionn {
margin-top: 300px !important;
}
.ttm-row.contact-section{
    margin-top: 310px !important;
}
}
@media (max-width: 991px){
    .project-single-section{
        margin-top: 160px !important;
    }
    .ttm-row.services-sectionn {
        margin-top: 200px !important;
        }
        .ttm-row.contact-section{
            margin-top: 250px !important;
        }
}