.contactus-btn {
  background-color: #ad7f47;
  position: fixed;
  top: 80%;
  right: -200px;
  z-index: 1000;
  border-radius: 50%;
  padding: 20px;
}

.contactus-btn.active {
  right: 20px;
}
