.services-page_section {
  padding-top: 15rem;
}

.large-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: var(--bs-body-font-family);
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 20px;
}

.large-card__right__img {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  max-width: 100%;
  overflow: hidden;
  transition: all 1s ease-in;
}
.large-card__right__img:hover > img {
  transform: scale(1.1);
  transition: all 1s ease-in;
}

.large-card__right__img img {
  border-radius: 20px;
  max-width: 450px;

  object-fit: cover;
}
.large-card__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 2rem;
}
.large-card__left a {
  text-decoration: none;
}
.large-card__left a:hover .large-card__left__title {
  color: #000;
}
.large-card__left__title {
  color: #ad7f47;
  transition: all 0.3s ease;
}
.large-card__left__description {
  color: #343a40;
  max-width: 70%;
}

@media screen and (max-width: 767px) {
  .services-page_section {
    padding-top: 5rem;
  }
  .large-card {
    flex-direction: column;
  }
  .large-card__left {
    margin-top: 2rem;
    align-items: center;
  }
}
@media screen and (max-width: 988px) {
  .large-card__right__img img {
    max-width: 300px;
  }
}

@media screen and (min-width: 517px) and (max-width: 767px) {
  .services-page_section {
    padding-top: 7rem;
  }
}
