.ttm-project-list-section {
    padding: 80px 0;
}

.ttm-project-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.ttm-project-list-item {
    width: calc(50% - 20px);
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    margin-bottom: 40px;
}

.ttm_single_image-wrapper {
    margin: 0 auto;
}

.ttm-project-list-item h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
}

.ttm-project-list-item p {
    font-size: 16px;
    line-height: 28px;
    color: #666;
    margin-bottom: 20px;
}

.ttm-project-list-item a.ttm-btn {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 10px 30px;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease 0s;
}

.ttm-project-list-item a.ttm-btn:hover {
    background-color: #666;
    color: #fff;
}

.ttm-project-details-section {
    background: #fff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 30px;
    text-align: center;
    margin-top: 40px;
}

.ttm-project-details-section h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 20px;
}

.ttm-project-details-section img {
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
}

.ttm-project-details-section p {
    font-size: 16px;
    line-height: 28px;
    color: #666;
    margin-bottom: 20px;
}

.ttm-category-buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.category-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
}

.category-button.selected {
    background-color: #ad7f47;
    color: #fff;
  }  

.category-button {
    color: #666;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 700;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 2px;
    transition: all 0.3s ease 0s;
    margin-right: 10px;
    margin-bottom: 10px;
    flex: 1;
    height: 60px;

}

@media (max-width: 991px) {
    .ttm-project-list-item {
      width: calc(50% - 20px);
    }
    .ttm-project-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 10vh;
    }
  }
  
  @media (max-width: 767px) {
    .ttm-project-list-item {
      width: 100%;
    }
    .ttm-project-list-wrapper {
      padding-top: 10vh;
    }
    .category-buttons {
        flex-wrap: wrap;
        margin-bottom: 20px;
      }
    
      .category-button {
        flex-basis: 50%;
        margin-right: 0;
    margin-bottom: 10px;
      }
    
  }
  