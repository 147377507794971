.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 999;
    color:#fff;
    top:30px;
    padding:0 60px 0 60px !important;
    position: sticky !important;

}
.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height:106px;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #000 !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}
.is-sticky .active{
    top:90px;
}
.icon{
    font-size: 1.2rem;
    cursor: pointer;
}
.nav-menu{
display:flex;
}
.navbar-bg{
   background-color: #f5f5f5; 
}
.hamburger{
    display:none;
    /* padding:1rem; */
}
li {
    padding:1rem;
}
ul , li{
    list-style: none;
}
.mobile-menu{
    display:none;
}
@media screen and (min-width:940px){
.nav-menu li {
    margin-left:30px;
margin: auto;
}
.logo2{
    display:none;
}
.nav-menu li a {
    color:white;
    text-decoration: none;
}
}
@media screen and (max-width:940px)
{

    .navbar {
        top:0 !important;
height:80px;
    }
    .nav-menu{
        display: none;
    }
    .nav-icons{
        display:none;
    }

    .hamburger{
        display:block;
    }
    .mobile-menu{
        display: flex;
        flex-direction: column;
        top:-120vh;
        left:0;
        /* align-items: center; */
        position: absolute;
        width:100%;
        transition: 0.3s;
        justify-content: space-between;
        /* color: rgba(255 , 255 , 255  , .05); */
        background-color: #000;
        color:#fff;
    }
    .mobile-menu li a {
        color:#fff;
        text-decoration: none;
        display:flex;
        z-index: 999;
        text-align: center;
    }
 
    .dark{
        color:#000;
    }
    .active{
        top:80px;
    }
    .mobile-menu-bottom {
        width:100%;
        text-align:center;

    }
    .social-icons{
        display:flex;
        justify-content: space-around;
        margin:2rem 0 ;
    }
    .social-icons .icon:hover {
transform: scale(1.5);
transition: 0.3s;
    }
    .mobile-menu button{
margin:1rem;
width:90%;
    }
    .hamburger svg{
        color:#fff !important;
    }
    .mobile-menu li {
        padding:1.2rem 1rem;
        margin:0 1rem;
        border-bottom:1px solid #ccc;    
    }

    .mobile-nav{
        margin:1rem 0;
    }
    .navbar-bg{
        background:#000;
    }
}
