.services-page_section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 15rem;
}

.large-card {
  display: flex;
  font-family: var(--bs-body-font-family);
  margin-bottom: 1rem;
  flex-direction: row;
}

.large-card:nth-child(odd) .large-card__right {
  order: 1;
}

.large-card:nth-child(odd) .large-card__left {
  order: 2;
}

.large-card:nth-child(even) .large-card__right {
  order: 2;
}

.large-card:nth-child(even) .large-card__left {
  order: 1;
}

/* .large-card__right {
  flex: 1;
} */


.large-card__right__img {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: all 1s ease-in;
}

.large-card__right__img:hover > img {
  transform: scale(1.1);
  transition: all 1s ease-in;
}

.large-card__right__img img {
  width: 450px;
  height: 100%;
  object-fit: cover;
}

.large-card__left {
   flex: 1;
   display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}


.large-card__left a {
  text-decoration: none;
}

.large-card__left a:hover .large-card__left__title {
  color: #000;
}

.large-card__left__title {
  color: #ad7f47;
  transition: all 0.3s ease;
}

.large-card__left__description {
  color: #343a40;
  max-width: 70%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .services-page_section {
    padding-top: 5rem;
  }
  
  .large-card {
    flex-direction: column;
  }
  
  .large-card__left {
    margin-top: 2rem;
    align-items: center;
  }
}

@media screen and (max-width: 988px) {
  .large-card__right__img img {
    max-width: 300px;
    height: 100%;
  }
}

@media screen and (min-width: 517px) and (max-width: 767px) {
  .services-page_section {
    padding-top: 7rem;
  }
}
