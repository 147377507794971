.alice-carousel__dots-item{
    display:none !important;
}
.featured-icon-box.style3{
    min-height:180px;
}
.item {
    display: inline-block;
    margin-right: 10px;
  }
  