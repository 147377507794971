.project-details-section {
  padding-top: 335px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.project-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #ad7f47;
}

.project-image {
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ad7f47;
}

.image-gallery {
  width: 100%;
}


.project-details-text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #333333;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 170px);
}

.loading .spinner {
  width: 50px;
  height: 50px;
  border-top: 3px solid #ad7f47;
  border-right: 3px solid #ad7f47;
  border-bottom: 3px solid #ad7f47;
  border-left: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .project-details-section {
    padding-top: 335px;
    padding-bottom: 60px;
  }

  .project-title {
    padding-top: 70px;
    font-size: 28px;
    margin-bottom: 20px;
  }

  .project-image {
    margin-bottom: 20px;
  }

  .project-details-text {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .loading {
    height: calc(100vh - 130px);
  }

  .loading .spinner {
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .project-details-section {
    padding-top: 150px;
    padding-bottom: 30px;
  }
}
